import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container, Row, Col} from "react-bootstrap" 
import head from '../../images/portfolio/SDLG/logoAll-green.png'
import logo1 from '../../images/portfolio/SDLG/SDLG-logo-01.png'
import logo2 from '../../images/portfolio/SDLG/SDLG-logo-02.png'
import logo3 from '../../images/portfolio/SDLG/SDLG-logo-03.png'
import logo4 from '../../images/portfolio/SDLG/SDLG-logo-04.png'
import { Link } from 'gatsby'

const PortfolioSDLGPage = () => {
  return (
    <LayoutWork pageTitle="SDLG">
      <Container><p className='info'>Research | On | <Link to='https://github.com/yasuperu/SDLG'>Github</Link></p></Container><hr/>
      <Container>
        <br/>
        <h1>SDLG</h1>
        <h2>The SDLG (Scalable Deep Learning Graphics) project aims to explore the potential of utilizing LSTM networks to generate text in an SVG style for the creation of vector logos. In order to achieve this, a proof of concept has been developed.</h2>
        <br/>
        <img src={head} className="fullimg" alt="thumbnail"/>
        <br/>
        <br/>
        <p>The project involves the use of 120 SVG files as the primary data source. The selected SVG files are path-based and have been chosen as the most suitable for logo formation. While other elements such as polygons, circles, rectangles, etc., may also contribute to the formation of a logo, the decision was made to solely use pathway SVG files to simplify the training process of the network.</p>
        <br/>
        <p>The process of converting the SVG files to text has been a crucial part of the project. The conversion has been carried out meticulously to ensure that the resulting text captures the essence of the original SVG files accurately. This has been done by carefully considering the various attributes associated with the path elements of the SVG files, such as stroke color, fill color, stroke width, etc.</p>
        <br/>
        <p>Overall, the SDLG project holds significant potential for the creation of vector logos. The use of LSTM networks to generate text in an SVG style offers a novel and scalable approach that can be further explored and developed. The project represents an exciting area of research that has the potential to revolutionize the field of logo design.</p>
        <Row>
          <Col sm={6}>
              <Row>
                <img src={logo1} alt="thumbnail"/>
              </Row>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={logo2} alt="thumbnail"/>
              </Row>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={logo4} alt="thumbnail"/>
              </Row>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={logo3} alt="thumbnail"/>
              </Row>
          </Col>
        </Row>
        </Container>
    </LayoutWork>
    
  )
}
export default PortfolioSDLGPage